import { Suspense, lazy } from "react"

import RouteLayout from "../layout"
const AppPromo = lazy(() => import("../../pages/app/promo"))
const AppShowCase = lazy(() => import("../../pages/app/showCase"))

const AppPromoRoutes = {
    path: "/app-promo",
    children: [
        {
            path: "",
            element: <Suspense fallback={<>Carregando...</>}><AppShowCase /></Suspense>,
        },
        {
            path: ":promo",
            element: <Suspense fallback={<>Carregando...</>}><AppPromo /></Suspense>,
        }
    ]
}

export default AppPromoRoutes