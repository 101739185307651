export type TypeDate =
    | 'yyyy-mm-dd'
    | 'yyyy-dd-mm'
    | 'dd-mm-yyyy'
    | 'mm-dd-yyyy'
    | 'yyyy/mm/dd'
    | 'dd/mm/yyyy'
    | 'mm/dd/yyyy'
    | 'hh:mm'
    | 'start'
    | 'end'
    | 'dd/mm'
    | 'dd'
    | 'mm';

type TypeHour = 'hhhmm' | 'hh:mm' | 'Thh:mm' | null;

const formatDate = (
    date: Date,
    typeDate: TypeDate,
    typeHour?: TypeHour,
    UTC?: boolean,
): string => {
    let newDate: Date;
    if (UTC) {
        newDate = new Date(date.toLocaleString('pt-BR', { timeZone: 'UTC' }));
    } else {
        newDate = new Date(date);
    }
    let month = `${newDate.getUTCMonth() + 1}`;
    let day = `${newDate.getUTCDate()}`;
    const year = newDate.getUTCFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    const dateformat: any = {
        'yyyy-mm-dd': [year, month, day].join('-'),
        'yyyy-dd-mm': [year, day, month].join('-'),
        'dd-mm-yyyy': [day, month, year].join('-'),
        'mm-dd-yyyy': [month, day, year].join('-'),
        'yyyy/mm/dd': [year, month, day].join('/'),
        'dd/mm/yyyy': [day, month, year].join('/'),
        'mm/dd/yyyy': [month, day, year].join('/'),
        'hh:mm': [hours, minutes].join(':'),
        'start': [year, month, day].join('-') + 'T00:00:00-03:00',
        'end': [year, month, day].join('-') + 'T23:59:59-03:00',
        'mm': month
    };

    const hourFormat = {
        hhhmm: ` | ${[hours, minutes].join('h')}`,
        'hh:mm': ` | ${[hours, minutes].join(':')}`,
        'Thh:mm': ` ${[hours, minutes].join(':')}`,
    };

    return dateformat[typeDate] + (typeHour ? hourFormat[typeHour] : '');
};

export const setTimeZone = (date: Date) => {
    return new Date(date.toLocaleString('pt-BR', { timeZone: 'UTC' }));
};

export const difDate = (d1: Date, d2: Date) => {
    const diffInMs = Number(new Date(formatDate(d2, 'yyyy-mm-dd'))) - Number(new Date(formatDate(d1, 'yyyy-mm-dd')));

    return diffInMs / (1000 * 60 * 60 * 24);
};

export const getWeekToday = (dateObj: Date) => {
    const arrayOfWeekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    const weekdayNumber = dateObj.getDay();
    const weekdayName = arrayOfWeekdays[weekdayNumber];
    return weekdayName;
};

export const convertUTCToLocalTime = (dateString: Date) => {
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    return new Date(milliseconds);
};

export type typeRange = 'initial' | 'end';

export const setRangeMonth = (type: typeRange) => {
    if (type === 'initial') {
        return new Date(new Date().setDate(1));
    }

    if (type === 'end') {
        const month = new Date().getMonth();
        const lastDay = new Date(new Date().getFullYear(), month + 1, 0).getDate();
        return new Date(new Date().setDate(lastDay));
    }

    return new Date();
};

export const stringDateToDate = (value: string): Date => {
    let d = value.split("/");
    const dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return new Date(dat);
};

export default formatDate;

// teste