import { Suspense, lazy } from "react"

import RouteLayout from "../layout"
const AppEvent = lazy(() => import("../../pages/app/event"))

const AppEventRoutes = {
    path: "/app-event",
    children: [
        {
            path: ":event",
            element: <Suspense fallback={<>Carregando...</>}><AppEvent /></Suspense>,
        }
    ]
}

export default AppEventRoutes