import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  min-height: 90%;
  display: flex;
  padding: 25px 40px 0px 40px;
  align-items: start !important;
  justify-content: start !important;
  color: black;
  flex-direction: column;

  @media (max-width: 1370px) {
    width: 84%;
  }

  @media (max-width: 1280px) {
    width: 100%;
    padding: 90px 10px 0px 10px;
  }
`;

export const ViewContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;

`;