import { lazy } from 'react';

import RouteLayout from "../layout"
const ActiveAccount = lazy(() => import('../../pages/admin/activeAccount/index'));

const AdminRoutes = {
    path: "/admin",
    children: [
        {
            path: "active-account",
            element: <RouteLayout page={<ActiveAccount />} />,
        },
    ]
}


export default AdminRoutes