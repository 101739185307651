import ptBR from '../assets/languages/ptBR.json';
import enUS from '../assets/languages/enUS.json';
import es from '../assets/languages/es.json';
import { TKeys } from './ITranslate';
import langs, { LangType } from './appLanguages';

const languages = { ptBR , enUS, es }

const translate = (payload: TKeys): string | Array<string> => {
    //@ts-ignore
    const message = languages[langs.currentLanguage][payload]

    if(!message) { return payload }

    const splitMessage = message.includes('{param}') ? message.split('{param}') : message

    return splitMessage
}

export default translate