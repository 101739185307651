import formatDate from "../../utils/formatDate"
import { moneyMask } from "../../utils/masks"

export const statusName = (status: number) => {
    const names = [
        '',
        'PRE_REGISTRATION',
        'PRE_APPROVED',
        'ACTIVE',
        'INACTIVE',
        'SUSPENDED',
    ]
    if (status >= 1 && status <= 5) {
        return names[status]
    }
    return ''
}

export const establishmentDTO = (payload: any) => {
    return payload?.map((data: any) => {
        return {
            name: data.name,
            cnpj: data.cnpj,
            fantasyName: data.fantasyName,
            email: data.email,
            phone: fullPhone(data.phones[0]),
            fullAddress: fullAddress(data?.fullAddress),
            status: statusName(data?.status),
            managerName: data?.managerContacts[0]?.managerName,
            managerEmail: data?.managerContacts[0]?.managerEmail,
            managerPhone: fullPhone(data?.managerContacts[0]?.managerPhone),
            priority: data.priority ? true : false,
            createdAt: data?.createdAt ? formatDate(data.createdAt, 'dd/mm/yyyy', 'Thh:mm') : '',
        }
    })
}

export const userAppDTO = (payload: any[]) => {
    return payload?.map((data: any) => {
        let notificationPreferences = '';
        let regions: any;
        if (data?.notificationPreferences?.regions?.length) {
            regions = data?.notificationPreferences?.regions[0]
        }
        if (regions?.neighborhood) {
            notificationPreferences += regions.neighborhood
        }
        if (regions?.city) {
            notificationPreferences += ' - ' + regions.city
        }

        return {
            name: data?.name,
            email: data?.email,
            phone: fullPhone(data?.phone),
            notificationPreferences,
            createdAt: data?.createdAt ? formatDate(data.createdAt, 'dd/mm/yyyy', 'Thh:mm') : '',
        }
    })
}

export const couponsDTO = (payload: any, filters: any) => {
    if (filters.status === '1') {
        payload = payload?.filter((item: any) => couponStatusDTO(item.status, item.consumptionFinalDate) === 'ACTIVE')
    }

    return payload
        ?.map((data: any) => {
            return {
                title: data?.title,
                description: data?.description,
                listPrice: data?.listPrice ? moneyMask(data.listPrice) : '',
                promotionalPrice: data?.promotionalPrice ? moneyMask(data.promotionalPrice) : '',
                globalUsageLimit: data?.globalUsageLimit,
                status: couponStatusDTO(data.status, data.consumptionFinalDate),
                consumptionStartDate: data?.consumptionStartDate ? formatDate(data.consumptionStartDate, 'dd/mm/yyyy', 'Thh:mm') : '',
                consumptionFinalDate: data?.consumptionFinalDate ? formatDate(data.consumptionFinalDate, 'dd/mm/yyyy', 'Thh:mm') : '',
                fantasyName: data?.establishment?.fantasyName ?? '',
                createdAt: data?.createdAt ? formatDate(data.createdAt, 'dd/mm/yyyy', 'Thh:mm') : '',
                imageUrl: data?.imageUrl
            }
        })
}

export const bizuusDTO = (payload: any) => {
    return payload?.map((data: any) => {
        return {
            title: data?.title,
            description: data?.description,
            listPrice: data?.listPrice ? moneyMask(data.listPrice) : '',
            promotionalPrice: data?.promotionalPrice ? moneyMask(data.promotionalPrice) : '',
            quantity: data?.quantity,
            paymentEnsured: data?.paymentEnsured ? 'YES' : 'NO',
            consumed: data?.consumedAt ? formatDate(data.consumedAt, 'dd/mm/yyyy', 'Thh:mm') : 'NO',
            status: bizuusStatusDTO(data.status),
            consumptionStartDate: data?.consumptionStartDate ? formatDate(data.consumptionStartDate, 'dd/mm/yyyy', 'Thh:mm') : '',
            consumptionFinalDate: data?.consumptionFinalDate ? formatDate(data.consumptionFinalDate, 'dd/mm/yyyy', 'Thh:mm') : '',
            fantasyName: data?.establishment?.fantasyName ?? '',
            customerEmail: data?.customer?.email ?? '',
            createdAt: data?.createdAt ? formatDate(data.createdAt, 'dd/mm/yyyy', 'Thh:mm') : '',
        }
    })
}

export const neighborhoodDTO = (payload: any) => {
    return payload?.map((data: any) => {
        const [neighborhood, city, uf] = data?._id?.split('-');
        return {
            neighborhood,
            city,
            uf,
            count: data.count,
            topic: data._id
        }
    })
}

export const couponStatusDTO = (value: number, date: Date) => {
    if (value === 1 && new Date(date) < new Date()) {
        value = 3;
    }
    const status = [
        'INACTIVE',
        'ACTIVE',
        'PAUSED',
        'FINISHED'
    ]
    return (value >= 0 && value <= 3) ? status[value] : ''
}

export const bizuusStatusDTO = (value: number) => {
    const status = [
        'ACTIVE',
        'CONSUMED',
        'REVERSED',
        'CANCELED'
    ]
    return (value >= 0 && value <= 3) ? status[value] : ''
}

export const fullPhone = (phones: any): string => {
    if (phones?.ddd && phones?.number) {
        return phones?.ddd + phones?.number
    }
    return '';
}

export const fullAddress = (address: any): string => {
    let fullText = '';
    if (address?.address) { fullText += `${address.address}` }
    if (address?.number) { fullText += ` - ${address.number}` }
    if (address?.neighborhood) { fullText += `, ${address.neighborhood}` }
    if (address?.city) { fullText += `, ${address.city}` }
    if (address?.uf) { fullText += ` - ${address.uf}` }
    if (address?.cep) { fullText += `, ${address.cep}` }
    return fullText;
}