import styled from 'styled-components';

import InputPasswordLight from '../../../components/InputPassword';

export const ContainerPage = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Gelion';
  @media (min-width: 1024px) {
    background-color: #0c4da2;
  }
`;

export const Container = styled.div`
  min-height: 460px;
  border-radius: 12px;
  display: flex;
  width: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  background-color: #ffffff;
  padding: 20px 0px;
  @media (min-width: 320px) {
    min-width: 80%;
    min-height: 50%;
    padding: 50px 20px;
  }
  @media (min-width: 768px) {
    min-width: 80%;
    min-height: 100%;
    padding: 50px 20px;
  }
  @media (min-width: 1024px) {
    min-width: 530px;
    min-height: 400px;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

export const ContainerUsed = styled.div`
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: black;
  background-color: #ffffff;
  @media (min-width: 320px) {
    min-width: 20%;
    min-height: 50%;
  }
  @media (min-width: 768px) {
    min-width: 100%;
    min-height: 100%;
  }
  @media (min-width: 1024px) {
    min-width: 530px;
    min-height: 460px;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

export const Logo = styled.img`
  width: 200px;
  height: 119px;
  margin-bottom: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 54px;
  margin-top: 30px;
    @media (min-width: 320px) {
  margin-top: 0px;
  }
  @media (min-width: 768px) {
  margin-top: 0px;

  }
`;

export const ContainerTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  color: #0C4DA2;
`;

export const TextContainer = styled.div`
  width: 80%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 0px 30px;
  margin-bottom: 30px;
`;

export const Span = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 0px 0px;
  margin-bottom: 30px;
`;

export const ContainerTitleUsed = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #0C4DA2;
`;

export const ContainerSubtitle = styled.span`
  color: rgba(0, 0, 0, 0.24);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const InputPasswordLightCustom = styled(InputPasswordLight)`
  height: 30px;
  border: 1px solid #EAE9E9;
  border-radius: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-indent: 16px;
  color: black;

  ::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
  
  @media (min-width: 320px) {
    width: 300px;
  }
  @media (min-width: 768px) {
    width: 403px;
  }
  @media (min-width: 1024px) {
    width: 403px;
  }
`

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    width: 80%;
  }
  @media (min-width: 768px) {
    width: 80%;
  }
  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const AcceptButtom = styled.button`
  height: 46px;
  width: 40px;
  background: #0C4DA2;
  border-radius: 30px;

  color: #FFF32A;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 320px) {
    width: 410px;
  }
  @media (min-width: 768px) {
    width: 434px;
  }
`;

export const ActionButtom = styled.button`
  height: 46px;
  width: 300px;

  background: #0C4DA2;
  border-radius: 30px;

  color: #FFF32A;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (min-width: 320px) {
    width: 250px;
  }
  @media (min-width: 768px) {
    width: 300px;
  }
`;
