import { Suspense, lazy } from "react"

import RouteLayout from "../layout"
import EventAccept from "../../pages/event/accepEvent"
const Event = lazy(() => import("../../pages/event"))
const CreateAndEditEvent = lazy(() => import("../../pages/event/createAndEdit"))

const EventRoutes = {
    path: "/event",
    children: [
        {
            path: "",
            element: <RouteLayout page={<Event />} isProtected={true} />,
        },
        {
            path: "create",
            element: <RouteLayout page={<CreateAndEditEvent />} isProtected={true} />,
        },
        {
            path: "edit",
            element: <RouteLayout page={<CreateAndEditEvent />} isProtected={true} />,
        },
        {
            path: "invite/:event/:choose/:participant/:newbie",
            element: <Suspense fallback={<>Carregando...</>}>
                    <EventAccept />
            </Suspense>,

        },
    ]
}

export default EventRoutes