/* eslint-disable import/no-anonymous-default-export */
export type LangType = 'ptBR' | 'enUS' | 'es'

const currentLanguage = 'ptBR';

export default {
  currentLanguage,
  setAppLanguage(nextLang: LangType): void {
    this.currentLanguage = nextLang;
  },
};
