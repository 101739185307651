enum BizuuConstants {
    AuthLocalKey = 'AuthenticateToken',
    AuthLocalUser = 'AuthenticateUser',
    MenusLocalKey = 'Menus',
    RoleLocalKey = 'Role',
    LastUrl = 'LastUrl',
    Language = 'Language',
    ExpiredToken = 'ExpiredToken',
}

export default BizuuConstants