import { Establishment } from '../../context/franchise/inteface';
import { FranchiseFormData } from '../../pages/franchise/createAndEdit/useHooks';
import FranchiseService from './services';

export const adminList = async (payload: any) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminList(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminCreate = async (payload: FranchiseFormData) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminCreate((payload));
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminEdit = async (franchiseId: string, franchiseManagerId: string, payload: any) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminEdit(franchiseId, franchiseManagerId, payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const edit = async (franchiseId: string, payload: any) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.edit(franchiseId, payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminInviteEstablishment = async (franchiseId: string, franchiseManagerId: string, payload: { establishment: string; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.franchiseAdminInviteEstablishments(franchiseId, franchiseManagerId, payload);
  } catch (error) {
    throw error;
  }
};

export const inviteEstablishment = async (franchiseId: string, payload: { establishment: string; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.franchiseInviteEstablishments(franchiseId, payload);
  } catch (error) {
    throw error;
  }
};

export const adminInviteOutherEstablishment = async (franchiseId: string, franchiseManagerId: string, payload: { outher: { email: string; name: string; }; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.franchiseAdminInviteOutherEstablishments(franchiseId, franchiseManagerId, payload);
  } catch (error) {
    throw error;
  }
};

export const inviteOutherEstablishment = async (franchiseId: string, payload: { outher: { email: string; name: string; }; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.franchiseInviteOutherEstablishments(franchiseId, payload);
  } catch (error) {
    throw error;
  }
};

export const adminResendInviteOutherEstablishment = async (franchiseManagerId: string, payload: { invite: string; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.adminResendInviteOutherEstablishment(franchiseManagerId, payload);
  } catch (error) {
    throw error;
  }
};

export const resendInviteOutherEstablishment = async (payload: { invite: string; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.resendInviteOutherEstablishment(payload);
  } catch (error) {
    throw error;
  }
};

export const updateFranchiseCoverAdminService = async (managerId: string, imageFile: any) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.uploadFranchiseCoverAdmin(managerId, imageFile);
  } catch (error) {
    throw error;
  }
};

export const editFranchiseEstablishmentTagAdmin = async (franchiseId: string, franchiseManagerId: string, payload: { franchiseParticipant: string; tag: string; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.editFranchiseEstablishmentTagAdmin(franchiseId, franchiseManagerId, payload);
  } catch (error) {
    throw error;
  }
};

export const editFranchiseEstablishmentTag = async (franchiseId: string, payload: { franchiseParticipant: string; tag: string; }) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.editFranchiseEstablishmentTag(franchiseId, payload);
  } catch (error) {
    throw error;
  }
};

export const updateFranchiseCoverService = async (imageFile: any) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.uploadFranchiseCover(imageFile);
  } catch (error) {
    throw error;
  }
};

export const updateFranchiseLogoAdminService = async (managerId: string, imageFile: any) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.uploadFranchiseLogoAdmin(managerId, imageFile);
  } catch (error) {
    throw error;
  }
};

export const updateFranchiseLogoService = async (imageFile: any) => {
  try {
    const franchiseService = new FranchiseService();
    return franchiseService.uploadFranchiseLogo(imageFile);
  } catch (error) {
    throw error;
  }
};

export const adminGetFranchiseById = async (franchiseId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminGetFranchiseById(franchiseId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getFranchiseById = async () => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.getFranchiseById();
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminGetParticipants = async (franchiseManagerId: string, page: number) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminGetParticipants(franchiseManagerId, page);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getParticipants = async (page: number) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.getParticipants(page);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminGetOutherParticipants = async (franchiseManagerId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminGetOutherParticipants(franchiseManagerId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getOutherParticipants = async () => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.getOutherParticipants();
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const franchiseDeleteAdmin = async (franchiseManagerId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.franchiseDeleteAdmin(franchiseManagerId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const franchiseAdminDeleteInvite = async (inviteId: string, managerId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminDeleteInvite(inviteId, managerId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const franchiseDeleteInvite = async (inviteId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.deleteInvite(inviteId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const deleteParticipant = async (participantId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.deleteParticipant(participantId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminDeleteParticipant = async (participantId: string, managerId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.adminDeleteParticipant(participantId, managerId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const listDropDownParticipants = async () => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.listDropDownParticipants();
    return response?.data?.response as Establishment[];
  } catch (error) {
    throw error;
  }
};

export const getFranchiseAccountParticipants = async (establishmentId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.getFranchiseAccountParticipants(establishmentId);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createProductFranchise = async (payload: any) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.createProduct(payload);
    return response.data.response;
  } catch (error) {
    throw error;
  }
};

export const editProductFranchise = async (payload: any, productId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.editProduct(payload, productId);
    return response.data.response;
  } catch (error) {
    throw error;
  }
};

export const productUploadImg = async (payload: any, productId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.uploadProductimg(payload, productId);
    return response;
  } catch (error) {
    throw error;
  }
};

export const receiversProduct = async (payload: { receivers: string[]; }, productId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.receiversProduct(payload, productId);
    return response;
  } catch (error) {
    throw error;
  }
};

export const establishmentProduct = async (productId: string) => {
  try {
    const franchiseService = new FranchiseService();
    const response = await franchiseService.getProdutEstablishments(productId);
    return response.data.response;
  } catch (error) {
    throw error;
  }
};