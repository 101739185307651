import * as React from 'react';
import GenericModal from '../../components/modal';

export const ModalContext = React.createContext<any | null>(null);

export interface IModalData {
    type: string;
    title: string;
    text: string;
    subText?: string;
    closeModal?: () => void
    buttons?: Array<{ text: string; action: () => void }>
}

const ModalProvider: React.FC<React.PropsWithChildren> = ({ children }: any) => {
    const [modalData, setModalData] = React.useState({} as IModalData);

    const info = (payload: any) => {
        setModalData({
            ...payload,
            // closeModal: () => {payload.closeModal(); close();},
            closeModal: close,
            type: 'info'
        })
    }

    const confirm = (payload: any) => {
        setModalData({
            ...payload,
            closeModal: () => {payload.closeModal(); close();},
            type: 'info'
        })
    }

    const close = () => {
        setModalData({
            type: '',
            title: '',
            text: '',
            subText: '',
            closeModal: () => null,
            buttons: [],
        })
    }

    return <ModalContext.Provider value={
        {
            info,
        }
    }>
        {children}
        {modalData?.type === 'info' && <GenericModal 
            modalIsOpen={true}
            title={modalData.title}
            text={modalData.text}
            closeModal={modalData.closeModal}
            buttons={modalData.buttons}
            subText={modalData.subText}
        />}
    </ModalContext.Provider>;
};

export default ModalProvider;