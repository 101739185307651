import { useContext } from "react";
import { Container } from "./styles";
import { FranchiseContext } from "../../context/franchise/context";
import { useYellowBar } from "./useYellowbar";
import { AccountContext } from "../../context/account/context";
import useWindowSize from "../../hooks/useWindowSize";
import t from "../../utils/useTranslate";

const prefix = (value: string) => {
    return `YellowBar${value}`;
}

const YellowBar = () => {
    const { isFranchiseAdmin } = useContext(AccountContext);
    const windowSize = useWindowSize();

    const {
        cnpj,
        fantasyName,
        showInRouter
    } = useYellowBar();

    if (showInRouter() && isFranchiseAdmin()) {
        return (
            <Container isMobile={windowSize.innerWidth <= 1280}>
                {`${t(prefix('TopDownText'))}${fantasyName} CNPJ ${cnpj}`}
            </Container>
        )
    } else {
        return <div />
    }
}

export default YellowBar;