import { apiValidator } from "./errorValidator";

const useTranslate = (payload: string, message: string = '') => {

  const response = apiValidator(`label.${payload}`, message)

  if (response.includes('label.')) {
    return message ? message : payload as string;
  }

  return response as string;
};

export default useTranslate;
