/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
	forwardRef,
	ForwardRefRenderFunction,
	InputHTMLAttributes,
	useState,
} from 'react';
import { FieldError } from 'react-hook-form';
import * as S from './styles';

export type InputType = 'text' | 'email';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	error?: FieldError | string;
	bold?: boolean;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
	{ name, label, error = null, bold, color, ...props },
	ref
): JSX.Element => {
	const [eyeOpened, setEye] = useState(false);

	const handleChangeState = () => {
		setEye(!eyeOpened);
	};

	return (
		<S.Container>
			<S.Label bold={bold} color={color}>
				{label}
			</S.Label>
			<S.Input
				{...props}
				type={eyeOpened ? 'text' : 'password'}
				ref={ref}
				hasError={!!error}
				name={name}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.preventDefault();
					}
				}}
			/>
			<S.ButtonEye
				onClick={(e) => {
					e.preventDefault();
					handleChangeState();
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.preventDefault();
					}
				}}
			>
				{!eyeOpened ? <S.EyeIconOpened /> : <S.EyeIconClose />}
			</S.ButtonEye>
			{error && (
				<S.MessageError>
					{typeof error === 'object' ? error.message : error}
				</S.MessageError>
			)}
		</S.Container>
	);
};

const InputPasswordLight = forwardRef(InputBase);

export default InputPasswordLight;
