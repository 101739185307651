import translate from "./translate";
import {TKeys} from './ITranslate'

export const apiValidator = (payload: any, message: string) => {
    if (!payload) {return message}
    if (payload.length && Array.isArray(payload)) {
        const key = Object.values(payload[0]?.constraints)[0]
        // @ts-ignore
        const splitKeys = key.includes(':') ? key.split(':') : key
        const dictionary = translate(Array.isArray(splitKeys) ? splitKeys[0] : key as TKeys)

        if(dictionary.length > 1 && Array.isArray(dictionary)) {
            let newMessage = ''
            dictionary.map((item: string, index: number) => {
                const concatMessage = index < (splitKeys.length - 1) ? splitKeys[index + 1] : ''
                newMessage = newMessage + item + concatMessage
            })
            return getField(payload[0].field) ? getField(payload[0].field) + newMessage.toLowerCase() : newMessage
        }
        return dictionary
    }
    
    if (payload && typeof payload === 'string') {
        return translate(payload as TKeys)
    }
    return message;
}


const getField = (value: TKeys) => {
    if(!value) return ''
    return `${translate(value as TKeys)} `
}