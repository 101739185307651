import * as React from 'react';
import BizuuConstants from '../../constants/bizuuConstants';
import { getMenusService, loginService, loginSessionService, registerService } from '../../services/account';
import { editAccountInfos } from '../../services/account/index';
import languageConfig, { LangType } from '../../utils/appLanguages';
import { ILogin, IRegister } from './../../services/account/account.types';
import { BeeviralReferralLinkQuery } from '../../pages/register/register';

export const AccountContext = React.createContext<any | null>(null);

const AccountProvider: React.FC<React.PropsWithChildren> = ({ children }: any) => {
    const [userData, setUserData] = React.useState<any>();
    const [userLanguage, setUserLanguage] = React.useState<any>();
    const [menusData, setMenusData] = React.useState<any>();

    const makeLogin = async (entity: ILogin) => {
        try {
            const response = await loginService(entity);
            if (response?.data?.error?.message === "invalidSingInAccount") {
                throw response?.data?.error?.message;
            }

            const { _id, name, email, token, role, user } = response?.data?.response;

            if (!_id || !name || !email || !token || !role || !user) { throw 'Existe algum problema no cadastro'; }

            setUserData(response?.data?.response);
            localStorage.setItem(BizuuConstants.AuthLocalKey, token);
            setUserLocalStorage(response?.data?.response);
            localStorage.setItem(BizuuConstants.RoleLocalKey, '');

            await getMenus();
        } catch (error) {
            throw error;
        }
    };

    const setUserLocalStorage = (payload: any) => {
        localStorage.setItem(BizuuConstants.AuthLocalUser, JSON.stringify({ ...payload }));
    };

    const makeLoginSession = async (codeToken: string) => {
        try {
            const response = await loginSessionService(codeToken);
            const { _id, name, email, token, role } = response?.data?.response;

            if (!_id || !name || !email || !token || !role) { throw 'Existe algum problema no cadastro'; }
            setUserData(response?.data?.response);
            localStorage.setItem(BizuuConstants.AuthLocalKey, token);
            setUserLocalStorage(response?.data?.response);
            localStorage.setItem(BizuuConstants.RoleLocalKey, '');

            await getMenus();
        } catch (error) {
            throw error;
        }
    };

    const makeRegister = async (entity: IRegister, query?: BeeviralReferralLinkQuery) => {
        try {
            const response = await registerService(entity, query);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getMenus = async () => {
        try {
            const responseMenus = await getMenusService();
            const data = responseMenus?.data?.response;
            localStorage.setItem(BizuuConstants.MenusLocalKey, JSON.stringify(data));
            setMenusData(data);
        } catch (error) {
            // throw error;
        }
    };

    const makeNewPassword = async (entity: IRegister) => {
        try {
            await registerService(entity);
        } catch (error) {
            throw error;
        }
    };

    const changeLanguage = (newLanguage: LangType) => {
        setUserLanguage(newLanguage);
        localStorage.setItem(BizuuConstants.Language, newLanguage);
        languageConfig.setAppLanguage(newLanguage);
    };

    const getUser = () => {
        const account = localStorage.getItem(BizuuConstants.AuthLocalUser);

        if (!userData && account) {
            const accountEntity = JSON.parse(account);
            setUserData(accountEntity);
            return accountEntity;
        }

        return userData;
    };

    const updateAccountInfos = async (payload: any) => {
        try {
            await editAccountInfos(payload);
            setUserData(payload);
            setUserLocalStorage(payload);
        } catch (error) {
            throw error;
        }
    };

    const logoutUser = () => {
        localStorage.clear();
        setUserData({});
        localStorage.setItem(BizuuConstants.Language, userLanguage);
    };

    function isAdmin() {
        return getUser()?.role === 'admin';
    }

    function isFranchiseAdmin(): boolean {
        return getUser()?.franchise;
    }

    React.useEffect(() => {
        if (['ptBR', 'enUS', 'es'].includes(localStorage.getItem(BizuuConstants.Language) as LangType)) {
            setUserLanguage(localStorage.getItem(BizuuConstants.Language));
            languageConfig.setAppLanguage(localStorage.getItem(BizuuConstants.Language) as LangType);
        } else {
            setUserLanguage('ptBR');
            languageConfig.setAppLanguage('ptBR' as LangType);
            localStorage.setItem(BizuuConstants.Language, 'ptBR');
        }
    }, [localStorage.getItem(BizuuConstants.Language)]);

    return <AccountContext.Provider value={
        {
            makeLogin,
            makeRegister,
            getUser,
            setUserData,
            setUserLocalStorage,
            makeNewPassword,
            menusData,
            getMenus,
            updateAccountInfos,
            logoutUser,
            changeLanguage,
            userLanguage,
            makeLoginSession,
            isAdmin,
            isFranchiseAdmin
        }
    }>
        {children}
    </AccountContext.Provider>;
};

export default AccountProvider;