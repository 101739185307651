import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { BsGearFill } from 'react-icons/bs';
import { CgLogOut } from 'react-icons/cg';
import { FaUser } from 'react-icons/fa';

export const Container = styled.div<{ open: boolean, admin?: boolean }>`
  min-height: 100vh;
  
  /* min-width: 205px; */
  min-width: ${({open}) => open ? '205px' : '50px' };
  padding: 0px 6px 0px 6px;
  background: ${({admin}) => admin ? '#F2F2F2' : '#0C4DA2' };
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Gelion';

  @media (max-width: 1280px) {
    min-height: ${({open}) => open ? '100%' : 'auto' };
    position: ${({open}) => open ? 'fixed' : 'relative' };
    z-index: ${({open}) => open ? '99' : '0' };
    min-width: ${({open}) => open ? '205px' : '35px' };
    padding-top: 30px;
    box-sizing: border-box;
  }

`;

export const LogoContainer = styled.div`
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
`;

export const LogoContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 8px;
`;

export const Logo = styled.img`
  width: 73px;
  height: 43px;
  display: flex;
`;

export const SideLink = styled(NavLink)<{ admin?: boolean }>`
  cursor: pointer;
  text-decoration: none;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({admin}) => admin ? '#0C4DA2' : '#FFF32A' };;
`;

export const ToggleSideBarInfo = styled("nav")`
  cursor: pointer;
  text-decoration: none;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #0C4DA2;
`;

export const ContainerSubSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubSideLink = styled(NavLink)`
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  color: #0C4DA2;
  margin-left: 16px;
  margin-bottom: 10px;
`;

export const ContentSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const ContentSide2 = styled.div`
  display: flex;
  width: 80%;
  margin-left: 35px;
  flex-direction: column;
  align-items: start;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  gap: 16px;
`;

export const UserContent = styled.div<{ admin?: boolean, disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  color: ${({admin}) => admin ? '#0C4DA2' : '#FFF32A' };
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer' };
  opacity: ${({disabled}) => disabled ? '0.6' : '1' };
`

export const GearIcon = styled(BsGearFill)<{ disabled?: boolean}>`
  margin-right: 6px;
`
export const ProfileIcon = styled(FaUser)`
  margin-right: 6px;
`

export const LogOutIcon = styled(CgLogOut)`
  margin-right: 6px;
`

export const ContentLogout = styled.div<{ admin?: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({admin}) => admin ? '#0C4DA2' : '#FFF32A' };

  @media (min-width: 414px) {
    padding-bottom: ${({admin}) => admin ? '250px' : '0px'}
  }

  @media (min-width: 580px) {
    padding-bottom: ${({admin}) => admin ? '290px' : '0px'}
  }
  
  @media (min-width: 768px) {
    padding-bottom: ${({admin}) => admin ? '320px' : '0px'}
  }

  @media (min-width: 1024px) {
    padding-bottom: ${({admin}) => admin ? '0px' : '0px'}
  }
`
