import { lazy } from 'react'

import RouteLayout from "../layout"
const EstablishmentList = lazy(() => import('../../pages/establishment-list'))

const EstablishmentListRoutes = {
    path: "/establishment-list",
    element: <RouteLayout page={<EstablishmentList />} isProtected={true} />,
}

export default EstablishmentListRoutes