import { Suspense, lazy } from "react"

const ForgotPage = lazy(() => import("../../pages/forgotPage"))
const Login = lazy(() => import("../../pages/login"))
const LoginConsume = lazy(() => import("../../pages/login-consume"))
const NewPassword = lazy(() => import("../../pages/new-password"))
const Register = lazy(() => import("../../pages/register"))

const AuthRoutes = {
    path: "/auth",
    children: [
        {
            path: "sign-up",
            element: <Suspense fallback={<>Carregando...</>}><Register /></Suspense>,
        },
        {
            path: "sign-in",
            element: <Suspense fallback={<>Carregando...</>}><Login /></Suspense>,
        },
        {
            path: "consume",
            element: <Suspense fallback={<>Carregando...</>}><LoginConsume /></Suspense>,
        },
        {
            path: "forgot-password",
            element: <Suspense fallback={<>Carregando...</>}><ForgotPage /></Suspense>,
        },
        {
            path: "new-password/:establishment/:code",
            element: <Suspense fallback={<>Carregando...</>}><NewPassword /></Suspense>,
        },
        {
            path: "reset-password/:establishment/:code",
            element: <Suspense fallback={<>Carregando...</>}><NewPassword /></Suspense>,
        }
    ]
}


export default AuthRoutes
