import { useContext } from "react";
import { FranchiseContext } from "../../context/franchise/context";
import { useLocation } from "react-router-dom";
import { AccountContext } from "../../context/account/context";

export function useYellowBar() {
    const { getSelectedEstablishment, list } = useContext(FranchiseContext);
    const { isFranchiseAdmin, getUser } = useContext(AccountContext);
    const selectedEstablishment = getSelectedEstablishment();
    const location = useLocation();
    const selectedIsMyEstablishment = selectedEstablishment?._id === getUser()?._id

    function showInRouter() {
        return (!selectedIsMyEstablishment &&
            isFranchiseAdmin &&
            list?.length &&
            routes.includes(location.pathname));
    }

    return {
        cnpj: selectedEstablishment?.cnpj,
        fantasyName: selectedEstablishment?.fantasyName,
        showInRouter
    }
}

const routes = ['/product', '/establishment', '/promotion']