/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import LogoImg from "../../../assets/bizu-logo.png";
import { apiValidator } from "../../../utils/errorValidator";
import t from "../../../utils/useTranslate";
import {
  ButtonContainer,
  Container,
  ContainerPage,
  ContainerText,
  ContainerTitle,
  Logo,
  ActionButtom,
  Span,
  TextContainer,
} from "./styles";
import { eventInviteFeedback } from "../../../services/events";
import Loading from "../../../components/loading";

const prefix = (value: string) => {
  return `EventInvite${value}`;
};

const EventAccept: React.FC = () => {
  const { event, choose, participant, newbie } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async () => {
    setLoading(true);
    try {
      await eventInviteFeedback({
        eventId: event as string,
        participantId: participant as string,
        accepted: choose === "accepted",
      });
    } catch (err: any) {
      toast.warning(
        apiValidator(err?.response?.data?.error, t(prefix("ErrorInviteEvent")))
      );
    } finally {
      setLoading(false);
    }
  };

  function renderText() {
    if (newbie === "true") {
      return (
        <>
          <Span>
            {t(
              prefix(
                choose === "accepted" ? "AcceptNewbieTxt" : "RefuseNewbieTxt"
              )
            )}
          </Span>
          <Span>
            {t(
              prefix(
                choose === "accepted"
                  ? "AcceptNewbieSubTxt"
                  : "RefuseNewbieSubTxt"
              )
            )}
          </Span>
        </>
      );
    }
    return (
      <>
        <Span>
          {t(prefix(choose === "accepted" ? "AcceptTxt" : "RefuseTxt"))}
        </Span>
        <Span>
          {t(prefix(choose === "accepted" ? "AcceptSubTxt" : "RefuseSubTxt"))}
        </Span>
      </>
    );
  }

  useEffect(() => {
    submit();
  }, []);

  return (
    <ContainerPage>
      {loading && <Loading />}
      <Logo src={LogoImg} alt="Logo" />
      <Container>
        <ContainerText>
          <ContainerTitle>{t(prefix("Title"))}</ContainerTitle>
        </ContainerText>
        <TextContainer>{renderText()}</TextContainer>
        <ButtonContainer>
          <ActionButtom
            onClick={() =>
              navigate(newbie ? "/auth/sign-in" : "/", { replace: true })
            }
          >
            {t(prefix(newbie === "true" ? "GoReg" : "GoBack"))}
          </ActionButtom>
        </ButtonContainer>
      </Container>
    </ContainerPage>
  );
};

export default EventAccept;
