import { lazy } from 'react'

import RouteLayout from "../layout"
const Product = lazy(() => import('../../pages/product'))

const ProductRoutes = {
    path: "/product",
    element: <RouteLayout page={<Product />} isProtected={true} />,
    // element: <RouteLayout page={<Product />} isProtected={true} />,
}

export default ProductRoutes