import { Navigate } from "react-router-dom";
import BizuuConstants from "../constants/bizuuConstants";

export const ProtectedRoute = ({ children }: any) => {
  const token = localStorage.getItem(BizuuConstants.AuthLocalKey);

  let lastUrl = window.location.hash.split('#')[1]

  localStorage.setItem(BizuuConstants.LastUrl, lastUrl)

  if (!token) {
    return <Navigate to="/auth/sign-in" />;
  }
  return children;
};