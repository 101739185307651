import { lazy } from "react";

import RouteLayout from "../layout";
const Franchises = lazy(() => import("../../pages/franchise"));
const CreateAndEditFranchises = lazy(() => import("../../pages/franchise/createAndEdit"));

const FranchiseRoutes = {
    path: "/franchises",
    children: [
        {
            path: "",
            element: <RouteLayout page={<Franchises />} isProtected={true} />,
        },
        {
            path: "create",
            element: <RouteLayout page={<CreateAndEditFranchises />} isProtected={true} />,
        },
        {
            path: "edit",
            element: <RouteLayout page={<CreateAndEditFranchises />} isProtected={true} />,
        },
        {
            path: "view",
            element: <RouteLayout page={<CreateAndEditFranchises />} isProtected={true} />,
        },
    ]
};

export default FranchiseRoutes;