import { lazy } from 'react'

import RouteLayout from "../layout"
const Privacity = lazy(() => import('../../pages/privacity'))

const PrivacityRoutes = {
    path: "/privacity",
    element: <RouteLayout page={<Privacity />} isProtected={false} />,
}

export default PrivacityRoutes