import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Modal = styled.div`
  position: fixed;
  z-index: 1000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  background-color: black;
  opacity: 0.3;
`;

export const Content = styled.div`
  background-color: 'rgba(0,0,0,0.4)';
  opacity: 1;
`;