import { lazy } from "react";

import RouteLayout from "../layout";
const PageBusinessRules = lazy(() => import("../../pages/businessRules/BusinessRules.page"));

const BusinessRulesRoutes = {
    path: "/businessRules",
    element: <RouteLayout page={<PageBusinessRules />} isProtected={true} />,
};

export default BusinessRulesRoutes;
