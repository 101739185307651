import { createGlobalStyle } from "styled-components";
// import Gelion from './assets/fonts/GELION-REGULAR.TTF'

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Gelion';
    src: local('Gelion Regular'), url('./assets/fonts/GELION-REGULAR.TTF') format('truetype');
  }

  body {
    font-family: 'Gelion' !important;
  }
`;

export default FontStyles;

// font-family: 'Gelion';
//   src: local('Gelion Regular'), url('./assets/fonts/GELION-REGULAR.TTF') format('truetype');
