import { lazy } from "react"

import RouteLayout from "../layout"
const CardBlackList = lazy(() => import("../../pages/card-black-list"))

const CardBlackListRoutes = {
    path: "/card",
    element: <RouteLayout page={<CardBlackList />} isProtected={true} />,
}

export default CardBlackListRoutes
