import './App.css';

import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AccountProvider from './context/account/context';
import Routes from './routes/routes';
import ModalProvider from './context/modal/context';
import ReportsProvider from './context/reports/context';
import FranchiseProvider from './context/franchise/context';

function App() {
  return (
    <div>
      <ToastContainer
        style={{ zIndex: '999999' }}
        limit={1}
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ModalProvider>
        <AccountProvider>
          <FranchiseProvider>
            <ReportsProvider>
              <RouterProvider router={Routes} />
            </ReportsProvider>
          </FranchiseProvider>
        </AccountProvider>
      </ModalProvider>
    </div>
  );
}

export default App;
