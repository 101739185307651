import { IMask } from "react-imask";

export const cnpjMask = IMask.createMask({
    mask: "00.000.000/0000-00",
    lazy: true,
});

export const telefoneMask = (value: string) => {
    const mask = value.length > 14 ? "(00) 00000-0000" : "(00) 0000-0000";
    return IMask.createMask({
        mask,
        lazy: true,
    });
};

export const phoneMask = (value: string) => {
    const digits = value.replace(/\D/g, "");

    if (digits.length < 10) {
        return digits;
    } else if (digits.length < 11) {
        return `(${digits.slice(0, 2)}) ${digits.slice(2, 6)}-${digits.slice(6)}`;
    } else {
        return `(${digits.slice(0, 2)}) ${digits.slice(2, 7)}-${digits.slice(7)}`;
    }
};

export const cepMask = (value: string) => {
    const mask = value.length > 9 ? "00000-000" : "00000-000";
    return IMask.createMask({
        mask,
        lazy: true,
    });
};

export const setMaskCnpj = (value: string | number) => {
    let cnpj = typeof value === "number" ? value.toString() : value;

    const onlyNumbers = /^\d+$/;
    if (!onlyNumbers.test(cnpj)) {
        return "";
    }

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const setMaskCnpjNumber = (value: string | number) => {
    let cnpj = typeof value === "number" ? value.toString() : value;
    cnpj = setMaskOnlyNumbers(cnpj);
    const onlyNumbers = /^\d+$/;
    if (!onlyNumbers.test(cnpj)) {
        return "";
    }

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const formatCPF = (cpfValue: string) => {
    let cpf = cpfValue;
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
};

export const formatCPFMask = (cpfValue: string) => {
    const onlyNumbers = cpfValue.replace(/\D/g, "");
    const cpfMask = IMask.createMask({
        mask: "000.000.000-00",
    });
    return cpfMask.resolve(onlyNumbers);
};

export const setMaskHourMinute = (value: string | number) => {
    let hhmm = value.toString();
    hhmm = hhmm.replace(/\D/g, "");
    return hhmm.replace(/(\d{2})(\d)/, "$1:$2");
};

export const setMaskOnlyNumbers = (value: string | number) => {
    const number = value.toString();
    return number.replace(/\D/g, "");
};

export const setCepMask = (value: string) => {
    const onlyNumbers = value.replace(/[^0-9]/g, "");
    if (onlyNumbers.length <= 5) {
        return onlyNumbers;
    } else if (onlyNumbers.length <= 8) {
        return onlyNumbers.slice(0, 5) + "-" + onlyNumbers.slice(5);
    } else {
        return onlyNumbers.slice(0, 5) + "-" + onlyNumbers.slice(5, 8) + "-" + onlyNumbers.slice(8);
    }
};

export const setMaskDiscount = (value: string) => {
    // value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    // const options = { minimumFractionDigits: 2 }
    // const result = new Intl.NumberFormat('pt-BR', options).format(
    //   parseFloat(value) / 100
    // )

    return Number(value).toFixed(0) + "%";
};

export const moneyMask = (value: string) => {
    value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat("pt-BR", options).format(parseFloat(value) / 100);

    return "R$ " + result;
};

export const moneyMaskNumber = (value: number) => {
    if (!value) {
        if (value === 0) {
            return "R$ " + value;
        }
        return;
    }
    return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2 });
};

export const onlyNumber = (value?: string) => {
    if (typeof value === "string") {
        return Number(value.replace(/\D/g, ""));
    }
    return 0;
};

export const onlyNumberStr = (value: string) => {
    return value.replace(/\D/g, "");
};

export const isEmailValid = (email: any) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
};

export const maskDate = (dateValue: string) => {
    let date = dateValue;
    date = date.replace(/\D/g, "");
    date = date.replace(/(\d{2})(\d)/, "$1/$2");
    date = date.replace(/(\d{2})(\d)/, "$1/$2");
    return date;
};
