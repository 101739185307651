import { lazy } from "react"

import RouteLayout from "../layout"
const PortalLogs = lazy(() => import("../../pages/portalLogs"))

const PortalLogsRoutes = {
    path: "/logs",
    element: <RouteLayout page={<PortalLogs />} isProtected={true} />,
}

export default PortalLogsRoutes