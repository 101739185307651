import ReportsService from "./services";

export const getEstablishmentList = async (params: any) => {
  try {
    const reportsService = new ReportsService();
    return reportsService.getEstablishmentsList(params);
  } catch (error) {
    throw error;
  }
};

export const getUsersAppList = async (params: any) => {
  try {
    const reportsService = new ReportsService();
    return reportsService.getUsersAppList(params);
  } catch (error) {
    throw error;
  }
};

export const getCouponsList = async (params: any) => {
  try {
    const reportsService = new ReportsService();
    return reportsService.getCouponsList(params);
  } catch (error) {
    throw error;
  }
};

export const getBizuusList = async (params: any) => {
  try {
    const reportsService = new ReportsService();
    return reportsService.getBizuusList(params);
  } catch (error) {
    throw error;
  }
};

export const getNeighborhoodList = async (params: any) => {
  try {
    const reportsService = new ReportsService();
    return reportsService.getNeighborhoodList(params);
  } catch (error) {
    throw error;
  }
};