import Modal from 'react-modal';
import useTranslate from '../../utils/useTranslate';
import { ButtonConfirm, ButtonContent, Container, Content, Header, Line, ModalSubText, ModalText, Title } from './styles';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
    },
};

const prefix = (value: string) => {
    return `Modal${value}`;
}

const GenericModal = (props: any) => (
    <Modal
        isOpen={props.modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <Container>
            <Header>
                <Title>{props?.title}</Title>
            </Header>
            <Line />
            <Content>
                <ModalText>{props?.text}</ModalText>
                <ModalSubText>{props?.subText}</ModalSubText>
            </Content>
            <Line />
            <ButtonContent>
                <ButtonConfirm onClick={props.closeModal}>{useTranslate(prefix('Close'))}</ButtonConfirm>
                {props?.buttons?.length &&
                    props?.buttons.map((item: any, index: number) => {
                        return (
                            <ButtonConfirm
                                key={index}
                                onClick={() => { item.action(); props.closeModal(); }}
                            >
                                {item.text}
                            </ButtonConfirm>
                        )
                    })}
            </ButtonContent>
        </Container>
    </Modal>
)

export default GenericModal;