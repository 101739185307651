import { Suspense } from "react";
import Layout from "../components/layout"
import { ProtectedRoute } from "./protectedRoutes"

interface IRouteLayout {
    page: any;
    isProtected?: boolean;
    suspense?: boolean;
}

const RouteLayout = ({
    page,
    isProtected = false,
    suspense = false,
}: IRouteLayout) => {
    const layoutPage = <Suspense fallback={<>Carregando...</>}>
        <Layout>{page}</Layout>
    </Suspense>

    if (isProtected) {
        return <Suspense fallback={<>Carregando...</>}>
            <ProtectedRoute>{layoutPage}</ProtectedRoute>
        </Suspense>
    }

    return layoutPage
}

export default RouteLayout;