import BizuuConstants from '../../constants/bizuuConstants';
import provider from '../../utils/providers';

class EventService {
  provider;
  token;
  constructor() {
    this.provider = provider;
    this.token = `Bearer ${localStorage.getItem(BizuuConstants.AuthLocalKey)}`
  }

  async list(payload: any) {
    return this.provider.get("/event/", {
      params: payload,
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminList(payload: any) {
    return this.provider.get("/event/admin", {
      params: payload,
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminCreate(payload: any) {
    return this.provider.post("/event", payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminEdit(payload: any, _id: string) {
    return this.provider.put(`/event/admin/${_id}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async edit(payload: any, _id: string) {
    return this.provider.put(`/event/${_id}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminInviteEstablishments(payload: any) {
    return this.provider.post("/event/admin/invite", payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async inviteEstablishments(payload: any) {
    return this.provider.post("/event/invite", payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async uploadLogoAdmin(id: string, payload: any) {
    const formData = new FormData();
    formData.append('file', payload);
    return this.provider.put(`/event/admin/upload-cover/${id}`, formData,
      {
        headers: {
          'Authorization': this.token,
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  async uploadLogo(id: string, payload: any) {
    const formData = new FormData();
    formData.append('file', payload);
    return this.provider.put(`/event/upload-cover/${id}`, formData,
      {
        headers: {
          'Authorization': this.token,
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  async adminGetEventById(eventId: string) {
    return this.provider.get(`/event/admin/${eventId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async getEventById(eventId: string) {
    return this.provider.get(`/event/${eventId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminGetParticipants(eventId: string, page = 1) {
    return this.provider.get(`/event/admin/${eventId}/participants`, {
      headers: {
        Authorization: this.token,
      },
      params: {
        page
      }
    });
  }

  async getParticipants(eventId: string, page = 1) {
    return this.provider.get(`/event/${eventId}/participants`, {
      headers: {
        Authorization: this.token,
      },
      params: {
        page
      }
    });
  }

  async adminGetOutherParticipants(eventId: string) {
    return this.provider.get(`/event/admin/${eventId}/outher/participants`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async getOutherParticipants(eventId: string) {
    return this.provider.get(`/event/${eventId}/outher/participants`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminChangeParticipant(payload: { eventId: string, participantId: string, status: number }) {
    return this.provider.put(`/event/${payload.eventId}/admin/participant/${payload.participantId}`, {
      status: payload.status
    }, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async changeParticipant(payload: { eventId: string, participantId: string, status: number }) {
    return this.provider.put(`/event/${payload.eventId}/participant/${payload.participantId}`, {
      status: payload.status
    }, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async eventInviteFeedback(payload: { eventId: string, participantId: string, accepted: boolean }) {
    return this.provider.put(`/event/${payload.eventId}/invite/${payload.participantId}`, {
      accepted: payload.accepted
    });
  }

  async getAllActiveEventByParticipant() {
    return this.provider.get(`/event/all/active`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async getAdminAllActiveEventByParticipant(establishmentId: string) {
    return this.provider.get(`/event/admin/all/active/${establishmentId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminEventReSendInvite(payload: { eventId: string, email: string, participantId: string, establishmentId?: string }) {
    return this.provider.put(`/event/${payload.eventId}/admin/re-send/invite/${payload.participantId}`, {
      ...payload
    },
      {
        headers: {
          Authorization: this.token,
        },
      });
  }

  async eventReSendInvite(payload: { eventId: string, email: string, participantId: string, establishmentId?: string }) {
    return this.provider.put(`/event/${payload.eventId}/re-send/invite/${payload.participantId}`, {
      ...payload
    }, {
      headers: {
        Authorization: this.token,
      },
    });
  }
  
  async adminEventDeleteInvite(_id: string) {
    return this.provider.delete(`/event/delete/invite/${_id}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async eventDeleteInvite(_id: string) {
    return this.provider.delete(`/event/delete/invite/${_id}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }
}

export default EventService;