import { lazy } from "react";

import RouteLayout from "../layout"
const ChangePassword = lazy(() => import("../../pages/account/changePassword"))
const Profile = lazy(() => import("../../pages/profile"))
const ConfigList = lazy(() => import("../../pages/config-list"))

const AccountRoutes = {
    path: "/account",
    children: [
        {
            path: "change-password",
            element: <RouteLayout page={<ChangePassword />} isProtected={true} />,
        },
        {
            path: "profile",
            element: <RouteLayout page={<Profile />} isProtected={true} />,
        },
        {
            path: "config",
            element: <RouteLayout page={<ConfigList />} isProtected={true} />,
        },
    ]
}

export default AccountRoutes