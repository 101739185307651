import { IEventData } from '../../pages/event/createAndEdit/useHooks';
import EventService from './services';

const makeEventObject = (payload: IEventData) => {
  const [lat, long] = (payload.geo as string).split(', ')
  return {
    title: payload.title,
    establishment: payload.establishment,
    description: payload.description,
    observation: payload.observation,
    appShowDate: payload.appShowDate,
    dates: payload.dates,
    discount: payload.discount.value,
    status: payload.status.value,
    fullAddress: {
      cep: payload.cep,
      address: payload.address,
      neighborhood: payload.neighborhood,
      city: payload.city,
      uf: payload.uf,
      number: payload.number,
      local: payload.local,
      complement: payload.complement,
      lat: lat,
      long: long
    },
  }
}

export const list = async (payload: any) => {
  try {
    const eventService = new EventService();
    const response = await eventService.list(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminList = async (payload: any) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminList(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminCreate = async (payload: IEventData) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminCreate(makeEventObject(payload));
    return response?.data?.response
  } catch (error) {
    throw error;
  }
};

export const adminEdit = async (payload: IEventData) => {
  try {
    const data = makeEventObject({
      ...payload,
      establishment: (payload.establishment as any)._id,
    })
    const eventService = new EventService();
    const response = await eventService
      .adminEdit({
        ...data,
        managerContacts: payload.managerContacts
      },
        payload?._id as string
      );
    return response?.data?.response
  } catch (error) {
    throw error;
  }
};

export const edit = async (payload: IEventData) => {
  try {
    const data = makeEventObject({
      ...payload,
      establishment: (payload.establishment as any)._id,
    })
    const eventService = new EventService();
    const response = await eventService
      .edit({
        ...data,
        managerContacts: payload.managerContacts
      },
        payload?._id as string
      );
    return response?.data?.response
  } catch (error) {
    throw error;
  }
};

export const adminInviteEstablishment = async (event: any, establishments?: any[]) => {
  try {
    const eventService = new EventService();
    return eventService.adminInviteEstablishments({
      event: event._id,
      userManager: event.userManager,
      establishments: establishments,
      outhers: []
    });
  } catch (error) {
    throw error;
  }
};

export const inviteEstablishment = async (event: any, establishments?: any[]) => {
  try {
    const eventService = new EventService();
    return eventService.inviteEstablishments({
      event: event._id,
      userManager: event.userManager,
      establishments: establishments,
      outhers: []
    });
  } catch (error) {
    throw error;
  }
};

export const adminInviteOutherEstablishment = async (event: any, outhers?: { name: string, email: string }[]) => {
  try {
    const eventService = new EventService();
    return eventService.adminInviteEstablishments({
      event: event._id,
      userManager: event.userManager,
      establishments: [],
      outhers
    });
  } catch (error) {
    throw error;
  }
};

export const inviteOutherEstablishment = async (event: any, outhers?: { name: string, email: string }[]) => {
  try {
    const eventService = new EventService();
    return eventService.inviteEstablishments({
      event: event._id,
      userManager: event.userManager,
      establishments: [],
      outhers
    });
  } catch (error) {
    throw error;
  }
};

export const updateCoverAdminService = async (id: string, payload: any) => {
  try {
    const eventService = new EventService();
    return eventService.uploadLogoAdmin(id, payload);
  } catch (error) {
    throw error;
  }
};

export const updateCoverService = async (id: string, payload: any) => {
  try {
    const eventService = new EventService();
    return eventService.uploadLogo(id, payload);
  } catch (error) {
    throw error;
  }
};

export const adminGetEventById = async (eventId: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminGetEventById(eventId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getEventById = async (eventId: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.getEventById(eventId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminGetParticipants = async (eventId: string, page: number) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminGetParticipants(eventId, page);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getParticipants = async (eventId: string, page: number) => {
  try {
    const eventService = new EventService();
    const response = await eventService.getParticipants(eventId, page);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminGetOutherParticipants = async (eventId: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminGetOutherParticipants(eventId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getOutherParticipants = async (eventId: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.getOutherParticipants(eventId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminChangeParticipant = async (payload: { eventId: string, participantId: string, status: number }) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminChangeParticipant(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const changeParticipant = async (payload: { eventId: string, participantId: string, status: number }) => {
  try {
    const eventService = new EventService();
    const response = await eventService.changeParticipant(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const eventInviteFeedback = async (payload: { eventId: string, participantId: string, accepted: boolean }) => {
  try {
    const eventService = new EventService();
    const response = await eventService.eventInviteFeedback(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getAllActiveEventByParticipant = async () => {
  try {
    const eventService = new EventService();
    const response = await eventService.getAllActiveEventByParticipant();
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const getAdminAllActiveEventByParticipant = async (establishmentId: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.getAdminAllActiveEventByParticipant(establishmentId);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminEventReSendInvite = async (payload: { eventId: string, email: string, participantId: string, establishmentId?: string }) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminEventReSendInvite(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const eventReSendInvite = async (payload: { eventId: string, email: string, participantId: string, establishmentId?: string }) => {
  try {
    const eventService = new EventService();
    const response = await eventService.eventReSendInvite(payload);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const adminEventDeleteInvite = async (_id: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.adminEventDeleteInvite(_id);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const eventDeleteInvite = async (_id: string) => {
  try {
    const eventService = new EventService();
    const response = await eventService.eventDeleteInvite(_id);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};