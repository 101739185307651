import { Suspense, lazy } from "react"

import RouteLayout from "../layout"
const AppEstablishment = lazy(() => import("../../pages/app/establishment"))
const AppShowCase = lazy(() => import("../../pages/app/showCase"))

const AppEstaRoutes = {
    path: "/esta",
    children: [
        {
            path: "",
            element: <Suspense fallback={<>Carregando...</>}><AppShowCase /></Suspense>,
        },
        {
            path: ":establishment",
            element: <Suspense fallback={<>Carregando...</>}><AppEstablishment /></Suspense>,
        }
    ]
}

export default AppEstaRoutes