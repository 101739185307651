import { lazy } from "react"

import RouteLayout from "../layout"
const Reports = lazy(() => import("../../pages/reports"))

const ReportsRoutes = {
    path: "/reports",
    element: <RouteLayout page={<Reports />} isProtected={true} />,
}

export default ReportsRoutes