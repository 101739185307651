import { lazy } from "react"

import RouteLayout from "../layout"
const Sales = lazy(() => import("../../pages/sales"))

const SalesRoutes = {
    path: "/sales",
    element: <RouteLayout page={<Sales />} isProtected={true} />,
}

export default SalesRoutes