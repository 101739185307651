import { useState, useEffect } from 'react';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
  });

  useEffect(() => {
      const handleResize = () => {
          setWindowSize({
              innerWidth: window.innerWidth,
              innerHeight: window.innerHeight
          });
      };
      window.addEventListener("resize", handleResize);
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);
  return windowSize;
}

export default useWindowSize;