import { lazy } from 'react'

import RouteLayout from '../layout'
const Establishment = lazy(() => import('../../pages/establishment'))

const EstablishmentRoutes = {
    path: "/establishment",
    element: <RouteLayout page={<Establishment />} isProtected={true} />,
}

export default EstablishmentRoutes