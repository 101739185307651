import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useWindowSize from '../../hooks/useWindowSize';

import Sidenav from '../Sidenav';
import SidenavMobile from '../SidenavMobile';
import { Container, Content, ViewContent } from './styles';
import YellowBar from '../yellowBar';

const Layout = ({ children }: any) => {
    const windowSize = useWindowSize();
    const [open, setOpen] = useState(windowSize.innerWidth >= 1280);
    return (
        <Container>
            {windowSize.innerWidth <= 1280 ? (<SidenavMobile open={open} setOpen={setOpen} />) : (<Sidenav open={open} setOpen={setOpen} />)}
            <ViewContent>
                <YellowBar />
                <Content isOpen={open}>
                    {children}
                </Content>
            </ViewContent>
        </Container>
    )
}

export default Layout
