/* eslint-disable @typescript-eslint/no-unused-expressions */
import BizuuConstants from '../../constants/bizuuConstants';
import { IActiveService } from '../../pages/admin/activeAccount/IActiveAccount';
import { BeeviralReferralLinkQuery } from '../../pages/register/register';
import provider from '../../utils/providers';
import { ILogin, INewPassword, IRegister, updatePriorityProps } from './account.types';

class AccountService {
  provider;
  token;
  constructor() {
    this.provider = provider;
    this.token = `Bearer ${localStorage.getItem(BizuuConstants.AuthLocalKey)}`;
  }

  async login(payload: ILogin) {
    try {
      return this.provider.post("/account/sign-in", {
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  }

  async loginSession(payload: string) {
    try {
      return this.provider.post(`/account/session/${payload}`);
    } catch (error) {
      throw error;
    }
  }

  async register(payload: IRegister, query?: BeeviralReferralLinkQuery) {
    try {
      let queryUrl = "";
      if (query) {
        for (const key in query) {
          queryUrl += `${key}=${query[key as keyof BeeviralReferralLinkQuery]}&`;
        }
      }
      return this.provider.post("/account/sign-up?" + queryUrl, {
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  }

  async signUpUser(payload: any) {
    try {
      return this.provider.post("/account/sign-up/user", {
        ...payload,
      },
        {
          headers: {
            Authorization: this.token,
          },
        });
    } catch (error) {
      throw error;
    }
  }

  async signUpUserAdmin(payload: any) {
    try {
      return this.provider.post("/account/admin/sign-up/user", {
        ...payload,
      },
        {
          headers: {
            Authorization: this.token,
          },
        });
    } catch (error) {
      throw error;
    }
  }

  async forgotPass(payload: string) {
    try {
      return this.provider.post("/account/reset-password", {
        email: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  async resendEmail(establishmentId: string) {
    try {
      return this.provider.post(`/account/retry-approved-email/${establishmentId}`,
        {},
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async changePriority(payload: updatePriorityProps) {
    try {
      return this.provider.put(`/account/priority`,
        payload,
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async getMenus() {
    try {
      return this.provider.get("/account/get-menus", {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async newPassword(payload: INewPassword) {
    try {
      return this.provider.put("/account/new-password", {
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  }

  async resetPasswordConfirmation(payload: INewPassword) {
    try {
      return this.provider.put("/account/reset-password-confirmation", {
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  }

  async updateEmail(email: string, establishmentId: string) {
    try {
      return this.provider.put(`/account/update-auth/${establishmentId}`, {
        email,
      },
        {
          headers: {
            'Authorization': this.token
          }
        });
    } catch (error) {
      throw error;
    }
  }

  async passcodeStatus(payload: any) {
    try {
      return this.provider.post("/account/passcode-status", {
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  }

  async listAccounts(filters: IActiveService, page: number, limit?: number) {
    try {
      return this.provider.post("/account/list", filters, {
        params: {
          page: page,
          limit: limit ? limit : 10
        },
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async listReducerAccounts(filters: IActiveService, page: number, limit?: number) {
    try {
      return this.provider.post("/account/list/reducer", filters, {
        params: {
          page: page,
          limit: limit ? limit : 10
        },
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async emListReducerAccounts(filters: IActiveService, page: number, limit?: number) {
    try {
      return this.provider.post("/account/em/list/reducer", filters, {
        params: {
          page: page,
          limit: limit ? limit : 10
        },
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async gteOneEstablishmentService(id: string) {
    try {
      return this.provider.get(`/account/admin/get-one/${id}`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async listUsersAdmin(filters: any, limit?: number) {
    try {
      return this.provider.get(`/account/admin/list/user`, {
        headers: {
          Authorization: this.token,
        },
        params: {
          ...filters,
        }
      });
    } catch (error) {
      throw error;
    }
  }

  async listUsers(filters: any, limit?: number) {
    try {
      return this.provider.get(`/account/list/user`, {
        headers: {
          Authorization: this.token,
        },
        params: {
          ...filters,
        }
      });
    } catch (error) {
      throw error;
    }
  }

  async activeAccount(establishment: string) {
    try {
      return this.provider.put(
        "/account/active-account",
        {
          establishment,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async editAccountInfos(payload: any, establishmentFranchieId?: string) {
    try {
      return this.provider.put(
        "/account/edit-account-infos",
        {
          ...payload,
        },
        {
          params: {
            establishmentId: establishmentFranchieId
          },
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async editAccountBanks(payload: any) {
    try {
      return this.provider.put(
        "/account/edit-account-banks",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async adminEditAccountBanks(payload: any, establishmendId: string) {
    try {
      return this.provider.put(
        `/account/admin/edit-account-banks/${establishmendId}`,
        {
          ...payload,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async editUserAdmin(payload: any) {
    try {
      return this.provider.put(
        "/account/admin/change/user",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async editUser(payload: any) {
    try {
      return this.provider.put(
        "/account/change/user",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async editEstablishmentInfos(payload: any) {
    try {
      return this.provider.put(
        "/account/edit-establishment-infos",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async changeAccountPassword(payload: any) {
    try {
      return this.provider.put(
        "/account/change-password",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async accountDetails() {
    try {
      return this.provider.get("/account/details", {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async establishmentDetails(id: string) {
    try {
      return this.provider.get(`/account/get-establishment-details/${id}`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async getLocalization(payload: any) {
    try {
      return this.provider.post("/account/get-geocoding", {
        ...payload,
      },
        {
          headers: {
            'Authorization': this.token
          }
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async getScore(id: string) {
    try {
      return this.provider.get(`/account/${id}/evaluations/score`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async getScoreAsEstablishment() {
    try {
      return this.provider.get(`/account/evaluations/score`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async getComments(id: string) {
    try {
      return this.provider.get(`/account/${id}/evaluations/comments`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async requestAccredit(franchise?: string, accountId?: string){
    try {
      return this.provider.post(`/account/ezcale-validation`, {}, {
        headers: {
          Authorization: this.token,
        },
        params: {
          establishmentId: franchise,
          targetIdAdmin:accountId
        }
      });
    } catch (error) {
      throw error;
    }
  }

  async getNewPasswordLink(establishment: string) {
    try {
      return this.provider.get(`/account/get/new-password-link/${establishment}`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async getAllSignDocs() {
    try {
      return this.provider.get(`/account/document/list`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async setEstablishmentSignDoc(establishment: string, doc: string) {
    try {
      return this.provider.put(`/account/change/sign-document/${establishment}`, {
        document: doc
      }, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteAccount(establishmentId: string) {
    try {
      return this.provider.delete(`/account/admin/${establishmentId}`, {
        headers: {
          Authorization: this.token,
        },
      });
    } catch (error) {
      throw error;
    }
  }
}

export default AccountService;
