import { lazy } from "react"

import RouteLayout from "../layout"
const Showcase = lazy(() => import("../../pages/showcase"))

const ShowcaseRoutes = {
    path: "/showcase",
    element: <RouteLayout page={<Showcase />} isProtected={true} />,
}

export default ShowcaseRoutes