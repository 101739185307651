import { lazy } from "react"

import RouteLayout from "../layout"
const Home = lazy(() => import("../../pages/home"))

const HomeRoutes = {
    path: "/",
    element: <RouteLayout page={<Home />} isProtected={true} />,
}

export default HomeRoutes