/* eslint-disable react-hooks/rules-of-hooks */
import 'react-toastify/dist/ReactToastify.css';

import { useContext, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import LogoBlueImg from '../../assets/bizu-logo-blue.png';
import LogoImg from '../../assets/bizu-logo.png';
import BizuuConstants from '../../constants/bizuuConstants';
import { AccountContext } from '../../context/account/context';
import useWindowSize from '../../hooks/useWindowSize';
import { ezcalePortalAuth } from '../../services/ezcale';
import translate from '../../utils/translate';
import useTranslate from '../../utils/useTranslate';
import EnvInfo from '../envInfo';
import Loading from '../loading';
import { GearIcon } from '../Sidenav/styles';
import {
    Container,
    ContainerSubSide,
    ContentLogout,
    ContentSide,
    ContentSide2,
    Logo,
    LogoContainer,
    LogoContainerMobile,
    LogoContent,
    LogOutIcon,
    ProfileIcon,
    SideLink,
    SidenavMenu,
    SubSideLink,
    ToggleSideBarInfo,
    UserContent,
} from './styles';
import { FranchiseContext } from '../../context/franchise/context';

const prefix = (value: string) => {
    return `SideNavMobile${value}`;
}

const SidenavMobile = ({ open, setOpen }: any) => {
    const windowSize = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState<number | null>(null);

    const { menusData, getUser, logoutUser } = useContext(AccountContext);
    const { clear } = useContext(FranchiseContext);
    const navigate = useNavigate();
    const menus = localMenu(menusData)
    const isAdmin = getUser()?.role === "admin"
    const isAbleConfig = getUser()?.user?.role === 'SUPERUSER' || getUser()?.user?.role === 'SUPERADMIN';
    
    const sortMenus = menus?.sort((a: any, b: any) => {
        return a?.order - b?.order;
    });

    const handleLogout = () => {
        const establishment = localStorage.getItem('EstablishmentLocal')
        localStorage.removeItem(BizuuConstants.AuthLocalKey)
        localStorage.clear();
        logoutUser();
        clear();
        establishment && localStorage.setItem('EstablishmentLocal', establishment)
        navigate('/auth/sign-in', { replace: true })
    }

    const handleLogin = async (action: string) => {
        setOpen((prev: any) => !prev)
        if (action === "navigation") return
        try {
            setLoading(true)
            const response = await ezcalePortalAuth()
            window.open(response?.data?.response?.url, '_blank')?.focus()
        } catch (error) {
            toast.warning('Não foi possível abrir o sistema financeiro tente novamente mais tarde');
        } finally {
            setLoading(false)
        }
    }

    const handleCheckToggle = (action: string, index: number) => {
        if (action !== 'toggle') { return }

        if (toggle === index) {

            setToggle(null)
            return
        }

        setToggle(index)
    }

    const handleClickMenusNav = (action: string, name: string ,index: number) => {
        if (action === 'toggle') {
            handleCheckToggle(action, index);

            return;
        }

        handleLogin(action);
    };

    const menusRender = () => sortMenus.map((item: any, index: number) => {
        if (item.active === false) { return }
        if (item.name === 'product' && getUser()?.role === 'admin') { return }
        if (item.name === 'promotion' && getUser()?.role === 'admin') { return }
        if (item.name === 'establishment' && getUser()?.role === 'admin') { return }
        if (item.name === 'security' && getUser()?.role !== 'admin') { return }

        return (
            <div key={index}>
                {(item.action !== 'toggle') && (
                    <SideLink
                        key={index}
                        to={item.route}
                        admin={isAdmin}
                        onClick={() => handleClickMenusNav(item.action, item.name, index)}
                    >
                        {item.route === window.location.hash.split('#')[1] ? <b>{translate(item.name)}</b> : translate(item.name)}
                    </SideLink>
                )}

                {item.action === 'toggle' && (
                    <>
                        <ToggleSideBarInfo
                            aria-hidden
                            onClick={() => handleClickMenusNav(item.action, item.name, index)}
                        >
                            {translate(item.name)}

                        </ToggleSideBarInfo>
                        <ContainerSubSide>
                            {(toggle && index) && (
                                item?.childrens?.map((subMenu: any, index: number) => (
                                    <SubSideLink
                                        to={subMenu.route}
                                        onClick={() => handleClickMenusNav(subMenu.action, subMenu.name, index)}
                                    >
                                        {item.route === window.location.hash.split('#')[1] ? <b>{translate(subMenu.name)}</b> : translate(subMenu.name)}
                                    </SubSideLink>
                                ))
                            )}
                        </ContainerSubSide>

                    </>
                )}
            </div>
        )
    })

    return (
        <>
            <Container open={open} admin={isAdmin}>
                <GiHamburgerMenu onClick={() => setOpen((prev: any) => !prev)} size={22} color={isAdmin ? '#0C4DA2' : '#FFF32A'} />
                <LogoContainerMobile isOpen={open}>
                    <Logo src={isAdmin ? LogoBlueImg : LogoImg} alt='Logo' />
                </LogoContainerMobile>
            </Container>
            {open && (
                <SidenavMenu admin={isAdmin}>
                    <div>
                        <LogoContainer>
                            <LogoContent>
                                <Logo src={isAdmin ? LogoBlueImg : LogoImg} alt='Logo' />
                            </LogoContent>
                            <EnvInfo />
                        </LogoContainer>
                        <ContentSide>
                            <ContentSide2>
                                {menusRender()}
                            </ContentSide2>
                        </ContentSide>
                    </div>
                    <div style={{ paddingLeft: '10px' }}>
                        <UserContent
                            admin={isAdmin}
                            onClick={() => navigate('/account/profile', { replace: true })}
                        >
                            <ProfileIcon /> {useTranslate(prefix('Profile'))}
                        </UserContent>
                        <UserContent
                            admin={isAdmin}
                            disabled={!isAbleConfig}
                            onClick={() => {
                                if (isAbleConfig) {
                                    navigate('/account/config', { replace: true })
                                }
                                
                            }}
                        >
                            <GearIcon  disabled={!isAbleConfig}/> {useTranslate(prefix('Settings'))}
                        </UserContent>
                        <ContentLogout
                            admin={isAdmin}
                            onClick={handleLogout}>
                            <LogOutIcon /> {useTranslate(prefix('Logout'))}
                        </ContentLogout>
                    </div>
                </SidenavMenu>
            )}
            {loading && <Loading />}
        </>
    )
}

const localMenu = (menusData: any) => {

    const hasStorageMenu = localStorage.getItem(BizuuConstants.MenusLocalKey)

    const validStorageMenu = hasStorageMenu ? JSON.parse(hasStorageMenu) : []

    return menusData ? menusData : validStorageMenu
}


export default SidenavMobile