import { createBrowserRouter, createHashRouter } from "react-router-dom";
import AccountRoutes from "./account";
import AdminRoutes from "./admin";
import AppPromoRoutes from "./appPromo";
import AppEstaRoutes from "./appEsta";
import AuthRoutes from "./auth";
import EstablishmentRoutes from "./establishment";
import EstablishmentListRoutes from "./establishment-list";
import FaqRoutes from "./faq";
import HomeRoutes from "./home";
import PrivacityRoutes from "./privacity";
import ProductRoutes from "./product";
import PromotionRoutes from "./promotion";
import SalesRoutes from "./sales";
import ReportsRoutes from "./reports/inde";
import PortalLogsRoutes from "./portalLogs";
import EventRoutes from "./event";
import CardBlackListRoutes from './card-black-list';
import AppUsersRoutes from "./app-users-list";
import AppEventRoutes from "./appEvent";
import FranchiseRoutes from "./franchise";
import ShowcaseRoutes from "./showcase";
import BusinessRulesRoutes from "./businessRules";

const Routes = createHashRouter([
    ProductRoutes,
    PromotionRoutes,
    HomeRoutes,
    AuthRoutes,
    AdminRoutes,
    AccountRoutes,
    EstablishmentRoutes,
    EstablishmentListRoutes,
    PrivacityRoutes,
    SalesRoutes,
    FaqRoutes,
    AppPromoRoutes,
    AppEstaRoutes,
    ReportsRoutes,
    PortalLogsRoutes,
    EventRoutes,
    CardBlackListRoutes,
    AppUsersRoutes,
    AppEventRoutes,
    FranchiseRoutes,
    ShowcaseRoutes,
    BusinessRulesRoutes
]);

export default Routes;
