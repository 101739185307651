import BizuuConstants from '../../constants/bizuuConstants';
import provider from '../../utils/providers';

class ReportsService {
  provider;
  token;
  constructor() {
    this.provider = provider;
    this.token = `Bearer ${localStorage.getItem(BizuuConstants.AuthLocalKey)}`
  }

  async getEstablishmentsList(params: any) {
    try {
      return this.provider.get(`/reports/establishments`,
      {
        headers: {
          'Authorization': this.token
        },
        params
      });
    } catch (error) {
      throw error;
    }
  }

  async getUsersAppList(params: any) {
    try {
      return this.provider.get(`/reports/users-app`,
      {
        headers: {
          'Authorization': this.token
        },
        params
      });
    } catch (error) {
      throw error;
    }
  }

  async getCouponsList(params: any) {
    try {
      return this.provider.get(`/reports/coupons`,
      {
        headers: {
          'Authorization': this.token
        },
        params
      });
    } catch (error) {
      throw error;
    }
  }

  async getBizuusList(params: any) {
    try {
      return this.provider.get(`/reports/bizuus`,
      {
        headers: {
          'Authorization': this.token
        },
        params
      });
    } catch (error) {
      throw error;
    }
  }

  async getNeighborhoodList(params: any) {
    try {
      return this.provider.get(`/reports/neighborhood`,
      {
        headers: {
          'Authorization': this.token
        },
        params
      });
    } catch (error) {
      throw error;
    }
  }
  
}

export default ReportsService;
