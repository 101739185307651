import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface LabelProps {
  color?: string;
  bold?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: ${(props) => (props.color ? props.color : "#000")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin-top: 10px;
  color: #0c4da2;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 30px;
`;

interface InputProps {
  hasError: boolean;
}

export const Input = styled.input<InputProps>`
  background-color: #fff;
  border-radius: 30px;
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  color: #000;
  outline: none;
  border: ${(props) =>
    props.hasError ? `2px solid #F44336` : `2px solid #e2e8f0`};

  &:focus {
    border: ${(props) =>
      props.hasError ? `2px solid #F44336` : `2px solid #e2e8f0`};
    outline: none;
  }
  ::placeholder{
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const MessageError = styled.p`
  color: #f44336;
  font-size: 0.8rem;
  margin-left: 30px;
`;

export const ButtonEye = styled.button`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 10px;
`;

export const EyeIconClose = styled(BsFillEyeFill).attrs({
  size: "1.2rem",
})`
  color: #000;
  background-color: transparent;
`;

export const EyeIconOpened = styled(BsFillEyeSlashFill).attrs({
  size: "1.2rem",
})`
  color: #000;
  background-color: transparent;
`;
