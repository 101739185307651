import { lazy } from "react"

import RouteLayout from "../layout"
const Faq = lazy(() => import("../../pages/faq"))

const FaqRoutes = {
    path: "/faq",
    element: <RouteLayout page={<Faq />} isProtected={true} />,
}

export default FaqRoutes