import styled from "styled-components";

export const Container = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFC70073;
  margin-top: ${({isMobile}) => isMobile ? '80px' : '0px'};
  margin-bottom: ${({isMobile}) => isMobile ? '-60px' : '0px'};
`;