import React, { useContext, useEffect, useState } from "react";
import { listDropDownParticipants } from "../../services/franchise";
import { AccountContext } from "../account/context";
import { DropDownOptions, Establishment } from "./inteface";

export const FranchiseContext = React.createContext<any | null>(null);

const FranchiseProvider: React.FC<React.PropsWithChildren> = ({ children }: any) => {
    const [selected, setSelected] = useState({} as Establishment);
    const [list, setList] = useState<Establishment[]>([]);
    const [refresh, setRefresh] = useState(false);
    const { getUser, isFranchiseAdmin } = useContext(AccountContext);
    const establishmentId = getUser()?._id;

    function getSelectedEstablishment() {
        return selected;
    }

    async function getDropDownList() {
        if (!isFranchiseAdmin()) return;
        const response = await listDropDownParticipants();
        setList(response);
    }

    async function updateDropDown() {
        if (!isFranchiseAdmin()) return;
        await getDropDownList();
        setRefresh(true);
    }

    function handleSetSelected(selected: DropDownOptions) {
        const establishment = list
            .find((item: Establishment) => item._id === selected?._id || item._id === selected?.value);
        if (!establishment) return;
        sessionStorage.setItem('franchise-establishment', JSON.stringify(establishment));
        setSelected(establishment ?? {} as Establishment);
    }

    function verifySelectedEstablishment() {
        if (selected?._id) return;
        const json = sessionStorage
            .getItem('franchise-establishment');
        if (!json) return;
        const establishment = JSON
            .parse(json);
        setSelected(establishment);
    }

    function clear() {
        setSelected({} as Establishment);
        sessionStorage.removeItem('franchise-establishment');
        setList([]);
    }

    useEffect(() => {
        getDropDownList();
        verifySelectedEstablishment();
    }, [getUser()]);

    useEffect(() => {
        if (!selected?._id && list?.length) {
            handleSetSelected(list.find((item: Establishment) => item._id === establishmentId) ?? {} as Establishment);
        }
    }, [list?.length]);

    useEffect(() => {
        if (refresh) {
            handleSetSelected(getSelectedEstablishment());
            setRefresh(false);
        }
    }, [refresh]);

    return <FranchiseContext.Provider
        value={{
            getSelectedEstablishment,
            list,
            handleSetSelected,
            updateDropDown,
            clear
        }}>
        {children}
    </FranchiseContext.Provider>;
};

export default FranchiseProvider;