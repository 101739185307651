import styled from "styled-components";
import { AiFillCloseCircle } from 'react-icons/ai';

export const Container = styled.div`
    width: 350px;
    min-height: 200px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`

export const Line = styled.div`
    width: 100%;
    border-top: 2px solid #eeeeee;
    margin-bottom: 15px;
`

export const Title = styled.span`
    font-size: 22px;
    font-weight: bold;
    color: #0C4DA2;
`

// export const CloseIcon = styled(AiFillCloseCircle)`
//     font-size: 25px;
//     font-weight: bold;
//     color: #0C4DA2;
//     cursor: pointer;
// `

export const Content = styled.div`
    min-height: 100px;
    display: flex;
    flex-direction: column;
`

export const ModalText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

export const ModalSubText = styled.span`
    font-size: 14px;
    margin-top: 12px;
`

export const ButtonContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export const ButtonConfirm = styled.button`
  width: 100px;
  height: 40px;

  background: #0c4da2;
  border-radius: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
`;