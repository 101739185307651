import { useContext, useEffect } from 'react';
import { AccountContext } from '../../context/account/context';
import { envs } from '../../utils/envs';
import { TextContent } from './styles';

const en = envs(process.env.REACT_APP_API_URL)

const EnvInfo = () => {
    const { getUser } = useContext(AccountContext);

    const handleVerifyVersion = () => {
        if (!en || en?.type === 'prod') { return }

        const color = en.type === 'dev' ?
            'orange' :
            getUser()?.role === 'admin' ?
                '#0C4DA2' :
                '#FFF32A'

        return {
            text: en.text,
            color: color
        }
    }

    if (en?.type !== 'prod') {
        return (
            <TextContent
                color={handleVerifyVersion()?.color || ''}
            >
                {handleVerifyVersion()?.text}
            </TextContent>
        )
    } else {
        return (<></>)
    }
}

export default EnvInfo