export const envs = (value: any) => {
    
    const variables = [
        {
            url: process.env.REACT_APP_API_URL_DEV,
            type: 'dev',
            text: 'AMB DEV'
        },
        {
            url: process.env.REACT_APP_API_URL_HML,
            type: 'hml',
            text: 'AMB HML'
        }, 
        {
            url: process.env.REACT_APP_API_URL_PROD,
            type: 'prod'
        },
    ]

    return variables.find(v => v.url === value)
}