import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { BsGearFill } from "react-icons/bs";
import { CgLogOut } from "react-icons/cg";
import { FaUser } from "react-icons/fa";

export const Container = styled.div<{ open: boolean, admin?: boolean }>`
  height: 80px;
  width: 100%;
  background: ${({admin}) => admin ? '#F2F2F2' : '#0C4DA2' };
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-family: "Gelion";
  padding: 0px 12px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const SidenavMenu = styled.div<{ admin?: boolean }>`
  height: calc(100%);
  width: 205px;
  background: ${({admin}) => admin ? '#F2F2F2' : '#0C4DA2' };
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 98;
  padding: 30px 12px;
  box-sizing: border-box;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const ToggleSideBarInfo = styled("nav")`
  cursor: pointer;
  text-decoration: none;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #0C4DA2;
`;

export const ContainerSubSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubSideLink = styled(NavLink)`
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  color: #0C4DA2;
  margin-left: 16px;
  margin-top: 10px;
  white-space: nowrap;
`;

export const ProfileIcon = styled(FaUser)`
  margin-right: 6px;
`

export const LogoContainer = styled.div`
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
`;

export const LogoContainerMobile = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  display: ${({ isOpen }) => (isOpen ? "none" : "flex")};
  align-items: center;
  justify-content: center;
`;

export const LogoContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 8px;
`;

export const Logo = styled.img`
  width: 73px;
  height: 43px;
  display: flex;
`;

export const SideLink = styled(NavLink)<{ admin?: boolean }>`
  cursor: pointer;
  text-decoration: none;
  color: black;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({admin}) => admin ? '#0C4DA2' : '#FFF32A' };
`;

export const ContentSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const ContentSide2 = styled.div`
  display: flex;
  width: 80%;
  margin-left: 35px;
  flex-direction: column;
  align-items: start;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  gap: 16px;
`;

export const UserContent = styled.div<{ admin?: boolean, disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: start;
  align-items: center;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  color: ${({admin}) => admin ? '#0C4DA2' : '#FFF32A' };
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer' };
  opacity: ${({disabled}) => disabled ? '0.6' : '1' };
`;

export const LogOutIcon = styled(CgLogOut)`
  margin-right: 6px;
`;

export const ContentLogout = styled.div<{ admin?: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 16px;
  justify-content: start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({admin}) => admin ? '#0C4DA2' : '#FFF32A' };
  padding-bottom: calc(20%);
`;
