import { lazy } from 'react'

import RouteLayout from "../layout"
const AppUsersList = lazy(() => import('../../pages/app-user-list'))

const AppUsersRoutes = {
    path: "/users-list",
    element: <RouteLayout page={<AppUsersList />} isProtected={true} />,
}

export default AppUsersRoutes