import axios from 'axios';
import BizuuConstants from '../constants/bizuuConstants';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: function (status) {
    if (status === 401) { 
      localStorage.removeItem(BizuuConstants.AuthLocalKey);
      localStorage.setItem(BizuuConstants.ExpiredToken, 'true');
      window.location.href = '/#/auth/sign-in';
      return true;
    }
    return status >= 200 && status < 300;
  }
});

const provider = instance

export default provider