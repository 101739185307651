import * as React from 'react';
import useXLSX from '../../utils/xlsx';
import { getBizuusList, getCouponsList, getEstablishmentList, getNeighborhoodList, getUsersAppList } from '../../services/reports';
import { bizuusDTO, couponsDTO, establishmentDTO, neighborhoodDTO, userAppDTO } from './utils';

export const ReportsContext = React.createContext<any | null>(null);

const ReportsProvider: React.FC<React.PropsWithChildren> = ({ children }: any) => {
    const createFileEstablishments = async (filters: Record<string, unknown>) => {
        try {
            const response = await getEstablishmentList(filters);
            const data = response?.data?.response
            if (!data) { throw 'Ocorreu um problema' }
            return establishmentDTO(data)
        } catch (error) {
            throw error;
        }
    }

    const createFileUsersApp = async (filters: Record<string, unknown>) => {
        try {
            const response = await getUsersAppList(filters);
            const data = response?.data?.response
            if (!data) { throw 'Ocorreu um problema' }
            return userAppDTO(data)
        } catch (error) {
            throw error;
        }
    }

    const createCoupons = async (filters: Record<string, unknown>) => {
        try {
            const response = await getCouponsList(filters);
            const data = response?.data?.response
            if (!data) { throw 'Ocorreu um problema' }
            return couponsDTO(data, filters)
        } catch (error) {
            throw error;
        }
    }

    const createBizuus = async (filters: Record<string, unknown>) => {
        try {
            const response = await getBizuusList(filters);
            const data = response?.data?.response
            if (!data) { throw 'Ocorreu um problema' }
            return bizuusDTO(data)
        } catch (error) {
            throw error;
        }
    }

    const createNeighborhood = async (filters: Record<string, unknown>) => {
        try {
            const response = await getNeighborhoodList(filters);
            const data = response?.data?.response
            if (!data) { throw 'Ocorreu um problema' }
            return neighborhoodDTO(data)
        } catch (error) {
            throw error;
        }
    }

    return <ReportsContext.Provider value={
        {
            createFileEstablishments,
            createFileUsersApp,
            createCoupons,
            createBizuus,
            createNeighborhood,
        }
    }>
        {children}
    </ReportsContext.Provider>;
};

export default ReportsProvider;