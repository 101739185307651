import { Container, Content, Modal } from "./styles";
import * as animationData from '../../assets/animations/loading.json';
import Lottie from 'react-lottie';

const Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };
    return (
        <>
            <Container style={{ zIndex: '999999999'}}>
                <Modal>
                    <Content>
                        <Lottie options={defaultOptions} />
                    </Content>
                </Modal>
            </Container>
        </>
    )
}

export default Loading;