import { lazy } from 'react'

import RouteLayout from "../layout"
const Promotion = lazy(() => import('../../pages/promotion'))

const PromotionRoutes = {
    path: "/promotion",
    element: <RouteLayout page={<Promotion />} isProtected={true} />,
}

export default PromotionRoutes